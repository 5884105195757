import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { getFullRouteUrl } from '@app/services/routeHelpers';

import { color as themeColors } from '@app/themes/mubi-theme';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';

import GenericBannerContainer from '@app/components/promotional-banner/banner-containers/GenericBannerContainer';
import PromotionalBanner from '@app/components/promotional-banner/banner-containers/PromotionalBanner';
import TranslateHtml from '@app/components/TranslateHtml';

type GiftingXmas2024BannerProps = {
  cookiePrefix: string;
};

const GiftingXmas2024Banner = ({
  cookiePrefix,
}: GiftingXmas2024BannerProps) => {
  const { t } = useTranslation('common');
  const i18nUrlData = useI18nUrlData();
  return (
    <GenericBannerContainer
      cookiePrefix={cookiePrefix}
      snowplowElement="gifting_xmas_24_banner"
    >
      {({ doBannerDismiss, doBannerClick }) => (
        <PromotionalBanner
          bgColor={themeColors.white}
          fontColor={themeColors.darkBackground}
          wholeBannerLink
          onDismiss={doBannerDismiss}
          onPromoLinkClick={() => {
            doBannerClick(
              getFullRouteUrl({
                url: '/gifts',
                i18nUrlData,
              }),
            );
          }}
        >
          <TranslateHtml
            t={t}
            i18nKey="common:common.banners.gifting_2024_xmas.text"
            i18nVars={{
              link: (
                <UnderlineText>
                  {t('common:common.banners.gifting_2024_xmas.link')}
                </UnderlineText>
              ),
            }}
          />
        </PromotionalBanner>
      )}
    </GenericBannerContainer>
  );
};

const UnderlineText = styled.span`
  text-decoration: underline;
  color: ${props => props.theme.color.giftRed};
`;

export default GiftingXmas2024Banner;
