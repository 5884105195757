import styled from '@emotion/styled';

import { color as themeColors } from '@app/themes/mubi-theme';

import GenericBannerContainer from '@app/components/promotional-banner/banner-containers/GenericBannerContainer';
import PromotionalBanner from '@app/components/promotional-banner/banner-containers/PromotionalBanner';

type MubiEditionsBannerProps = {
  cookiePrefix: string;
};

const MubiEditionsBanner = ({ cookiePrefix }: MubiEditionsBannerProps) => (
  <GenericBannerContainer
    cookiePrefix={cookiePrefix}
    snowplowElement="mubi_editions_banner"
  >
    {({ doBannerDismiss, doBannerClick }) => (
      <PromotionalBanner
        bgColor="#f2f0f0"
        fontColor={themeColors.black}
        wholeBannerLink
        onDismiss={doBannerDismiss}
        onPromoLinkClick={() => {
          doBannerClick('https://mubieditions.com/', true);
        }}
        useLegacyMQ
      >
        Introducing MUBI Editions! <UnderlineText>Pre-order</UnderlineText> our
        first book now.
      </PromotionalBanner>
    )}
  </GenericBannerContainer>
);

const UnderlineText = styled.span`
  text-decoration: underline;
`;

export default MubiEditionsBanner;
