import styled from '@emotion/styled';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import GenericBannerContainer from '@app/components/promotional-banner/banner-containers/GenericBannerContainer';
import FooterBanner from '@app/components/promotional-banner/banner-content/FooterBanner';

const RetrospectiveBanner2024 = ({ cookiePrefix }) => {
  const promoPromoted = useAppSelector(state => state.appState.promoPromoted);

  if (promoPromoted && promoPromoted?.type === 'retro_2024') {
    const backgroundColour = promoPromoted.start_colour || '#00ff9e';
    const foregroundColour = promoPromoted.body_colour || '#000000';

    return (
      <GenericBannerContainer
        cookiePrefix={cookiePrefix}
        snowplowElement="retro_2024_banner"
      >
        {({ doBannerDismiss, doBannerClick }) => (
          <StyledFooterBanner
            text={promoPromoted.body}
            ctaText={promoPromoted.cta}
            bannerColors={{
              background: backgroundColour,
              ctaText: foregroundColour,
              text: foregroundColour,
              dismissLink: foregroundColour,
            }}
            onDismiss={doBannerDismiss}
            onClick={() => {
              doBannerClick(promoPromoted.url);
            }}
          />
        )}
      </GenericBannerContainer>
    );
  }

  return null;
};

const StyledFooterBanner = styled(FooterBanner)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
  }
`;

export default RetrospectiveBanner2024;
