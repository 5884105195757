import useTranslation from 'next-translate/useTranslation';

import { getFullRouteUrl } from '@app/services/routeHelpers';

import { color as themeColors } from '@app/themes/mubi-theme';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';

import GenericBannerContainer from '@app/components/promotional-banner/banner-containers/GenericBannerContainer';
import PromotionalBanner from '@app/components/promotional-banner/banner-containers/PromotionalBanner';

type NotebookIssue6BannerProps = {
  cookiePrefix: string;
};

const NotebookIssue6Banner = ({ cookiePrefix }: NotebookIssue6BannerProps) => {
  const { t } = useTranslation('common');
  const i18nUrlData = useI18nUrlData();
  return (
    <GenericBannerContainer
      cookiePrefix={cookiePrefix}
      snowplowElement="notebook_issue_6_banner"
    >
      {({ doBannerDismiss, doBannerClick }) => (
        <PromotionalBanner
          bgColor={themeColors.notebookMagazine.ctaButton}
          fontColor={themeColors.white}
          wholeBannerLink
          onDismiss={doBannerDismiss}
          onPromoLinkClick={() => {
            doBannerClick(
              getFullRouteUrl({
                url: '/notebook/magazine',
                i18nUrlData,
              }),
            );
          }}
        >
          {t('common:common.banners.notebook_issue_six')}
        </PromotionalBanner>
      )}
    </GenericBannerContainer>
  );
};

export default NotebookIssue6Banner;
