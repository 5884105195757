import styled from '@emotion/styled';

import CloseButton from '@app/components/buttons/CloseButton';
import { SelectArrowIcon } from '@app/components/icons/ArrowIcons';
import PageSection from '@app/components/layout/PageSection';

type FooterBannerColors = {
  background: string;
  ctaText: string;
  text: string;
  dismissLink: string;
};

type FooterBannerProps = {
  text: string;
  bannerColors: FooterBannerColors;
  onDismiss: () => void;
  onClick: () => void;
  ctaText?: string;
  className?: string;
};

const FooterBanner = ({
  text,
  ctaText,
  bannerColors,
  onDismiss,
  onClick,
  className,
}: FooterBannerProps) => {
  return (
    <Positioning>
      <Container backgroundColor={bannerColors.background} onClick={onClick}>
        <PageSection relative>
          <TextContent className={className}>
            <Text textColor={bannerColors.text}>{text}</Text>
            {ctaText && (
              <StyledLink ctaTextColor={bannerColors.ctaText}>
                {ctaText}
                <ArrowContainer>
                  <SelectArrowIcon
                    direction="right"
                    color={bannerColors.ctaText}
                    width="100%"
                  />
                </ArrowContainer>
              </StyledLink>
            )}
          </TextContent>
          <CloseButtonContainer>
            <CloseButton
              handleClose={event => {
                event.stopPropagation();
                onDismiss();
              }}
              color={bannerColors.dismissLink}
              width="15px"
              padding="6px"
            />
          </CloseButtonContainer>
        </PageSection>
      </Container>
    </Positioning>
  );
};

export default FooterBanner;

const Positioning = styled.div`
  position: fixed;
  bottom: 0vh;
  left: 0;
  right: 0;
  z-index: 15;
`;

const Container = styled.div<{
  backgroundColor: string;
}>`
  width: 100vw;
  position: relative;
  padding: 12px 50px 12px 20px;

  background-color: ${props => props.backgroundColor};

  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);

  cursor: pointer;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 12px 38px;
  }
`;

const TextContent = styled.div`
  padding-right: 40px;

  font-size: 16px;
  line-height: 24px;
  font-weight: bold;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 18px;
    line-height: 26px;

    height: 27px;
    align-items: center;
    display: flex;
  }
`;

const Text = styled.span<{
  textColor: string;
}>`
  color: ${props => props.textColor};
  padding-right: 6px;
`;

const ArrowContainer = styled.div`
  margin-left: 4px;
  margin-top: 2px;
  width: 11px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-top: 2px;
    margin-left: 7px;
    width: 13px;
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 12px;
  top: -3px;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    top: 0px;
  }
`;

const StyledLink = styled.div<{
  ctaTextColor: string;
}>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  color: ${props => props.ctaTextColor};

  &:visited {
    color: ${props => props.ctaTextColor};
  }
  &:hover {
    color: ${props => props.ctaTextColor};
  }

  &:hover {
    text-decoration: underline;
  }
`;
