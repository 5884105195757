import { FunctionComponent } from 'react';

import { Film } from '@app/api/resources/Film';
import { FilmGroupId } from '@app/api/resources/FilmGroup';
import { CurrentUser } from '@app/api/resources/User';

import { checkIfUserDismissedBanner } from '@app/services/banners/banner-cookies';
import {
  isMoreThanOneYearAgo,
  isMoreThanSixMonthsAgo,
  isNowInSpecificTimezoneAfterDate,
  isNowInSpecificTimezoneBeforeDate,
} from '@app/services/date-utils';

import { ObjectOfStrings } from '@app/types/utility-types';

type Restrictions = {
  unsubscribedOnly?: boolean;
  activeAndNotTrialOnly?: boolean;
  activeSubscribersOnly?: boolean;
  notSubscribedToNotebookMagazineOnly?: boolean;
  onlyCreatedSubscriptionOverYearAgo?: boolean;
  onlyCreatedSubscriptionOverSixMonthsAgo?: boolean;
  geoLocation?: string[];
  allowedInLanguages?: string[];
  excludeGeoLocations?: string[];
  isCDMRevokedDueToBrowserVersion?: boolean;
  isCDMRevokedDueToWindowsVersion?: boolean;
  failedPaymentOnly?: boolean;
  promoPromotedType?: string;
};

type DismissibleBannerComponent = FunctionComponent<{
  cookiePrefix: string;
  stopShowingOn?: string;
}>;

export type DismissibleBannerConfiguration = {
  bannerType: string;
  isDismissible: true;

  bannerComponent: DismissibleBannerComponent;

  cookiePrefix: string;

  startShowingOn?: string;
  stopShowingOn?: string;

  // After dismissing the first time, how long to wait until we show it a second time.
  showAgainAfterXDays?: number;

  restrictions?: Restrictions;
};

export type NonDismissibleBannerComponent = FunctionComponent<{
  film?: Film;
  filmGroupId?: FilmGroupId;
}>;

export type NonDismissibleBannerConfiguration = {
  bannerType: string;
  isDismissible: false;
  bannerComponent: NonDismissibleBannerComponent;
  restrictions: Restrictions;
};

type PathName = string;

export type BannerConfiguration =
  | DismissibleBannerConfiguration
  | NonDismissibleBannerConfiguration;

export type BannersOnPages = {
  [key: PathName]: BannerConfiguration[];
};

const isBannerInsideOfShowingAtDates = (
  banner: DismissibleBannerConfiguration,
  countryFullTimeZone: string,
) => {
  if (banner.startShowingOn && banner.stopShowingOn) {
    if (
      isNowInSpecificTimezoneAfterDate(
        banner.startShowingOn,
        countryFullTimeZone,
      ) &&
      isNowInSpecificTimezoneBeforeDate(
        banner.stopShowingOn,
        countryFullTimeZone,
      )
    ) {
      return true;
    }
    return false;
  }

  if (banner.startShowingOn && !banner.stopShowingOn) {
    if (
      isNowInSpecificTimezoneAfterDate(
        banner.startShowingOn,
        countryFullTimeZone,
      )
    ) {
      return true;
    }
    return false;
  }

  if (banner.stopShowingOn && !banner.startShowingOn) {
    if (
      isNowInSpecificTimezoneBeforeDate(
        banner.stopShowingOn,
        countryFullTimeZone,
      )
    ) {
      return true;
    }
    return false;
  }

  return true;
};

type ShouldShowBannerParams = {
  banner: BannerConfiguration;
  dismissedBanners: ObjectOfStrings;
  countryFullTimeZone: string;
  geoLocation: string;
  currentLanguage: string;
  user?: CurrentUser;
  magazineSubStatus?: string;
  detectedBrowser?: string;
  detectedBrowserVersion?: string;
  detectedOperatingSystem?: string;
  promoPromotedType?: string;
};

export const shouldShowBanner = ({
  banner,
  dismissedBanners,
  countryFullTimeZone,
  user,
  geoLocation,
  currentLanguage,
  magazineSubStatus,
  detectedBrowser,
  detectedBrowserVersion,
  detectedOperatingSystem,
  promoPromotedType,
}: ShouldShowBannerParams) => {
  if (banner.isDismissible) {
    const hasUserDismissedBanner = checkIfUserDismissedBanner(
      banner.cookiePrefix,
      dismissedBanners,
    );
    if (hasUserDismissedBanner) {
      return false;
    }

    const insideShowingAtDates = isBannerInsideOfShowingAtDates(
      banner,
      countryFullTimeZone,
    );

    if (!insideShowingAtDates) {
      return false;
    }
  }

  const bannerRestrictions = banner.restrictions;

  const noRestrictionsSetForBanner =
    !bannerRestrictions || typeof bannerRestrictions !== 'object';

  if (noRestrictionsSetForBanner) {
    return true;
  }

  if (bannerRestrictions?.unsubscribedOnly) {
    if (user && user?.active_subscriber) {
      return false;
    }
  }

  if (bannerRestrictions?.activeAndNotTrialOnly) {
    return (
      user &&
      (user.is_admin ||
        (user?.active_subscriber && user?.subscription?.status !== 'trial'))
    );
  }

  if (bannerRestrictions?.activeSubscribersOnly) {
    if (!user || (user && !user?.active_subscriber)) {
      return false;
    }
  }

  if (bannerRestrictions?.notSubscribedToNotebookMagazineOnly) {
    const isPastOrPresentNotebookMagSubscriber =
      magazineSubStatus === 'paid' ||
      magazineSubStatus === 'marked_for_cancellation' ||
      magazineSubStatus === 'cancelled';
    if (isPastOrPresentNotebookMagSubscriber) {
      return false;
    }
  }

  if (bannerRestrictions?.onlyCreatedSubscriptionOverYearAgo) {
    if (!(user && isMoreThanOneYearAgo(user?.created_at))) {
      return false;
    }
  }

  if (bannerRestrictions?.onlyCreatedSubscriptionOverSixMonthsAgo) {
    if (!(user && isMoreThanSixMonthsAgo(user?.created_at))) {
      return false;
    }
  }

  if (
    bannerRestrictions?.geoLocation &&
    Array.isArray(bannerRestrictions?.geoLocation)
  ) {
    if (!bannerRestrictions?.geoLocation.includes(geoLocation)) {
      return false;
    }
  }

  if (
    bannerRestrictions?.excludeGeoLocations &&
    Array.isArray(bannerRestrictions?.excludeGeoLocations)
  ) {
    if (bannerRestrictions?.excludeGeoLocations.includes(geoLocation)) {
      return false;
    }
  }

  if (
    bannerRestrictions?.allowedInLanguages &&
    Array.isArray(bannerRestrictions?.allowedInLanguages)
  ) {
    if (!bannerRestrictions?.allowedInLanguages.includes(currentLanguage)) {
      return false;
    }
  }

  const doesCDMSupportBrowserVersion = !(
    (detectedBrowser === 'chrome' || detectedBrowser === 'edge') &&
    parseInt(detectedBrowserVersion, 10) < 115
  );

  const doesCDMSupportWindowsVersion = !(
    detectedOperatingSystem === 'Windows 7' ||
    detectedOperatingSystem === 'Windows 8' ||
    detectedOperatingSystem === 'Windows 8.1'
  );

  if (bannerRestrictions.isCDMRevokedDueToBrowserVersion) {
    if (!doesCDMSupportWindowsVersion) {
      // We want the update windows banner to take precedence.
      return false;
    }
    if (doesCDMSupportBrowserVersion) {
      return false;
    }
  }

  if (bannerRestrictions.isCDMRevokedDueToWindowsVersion) {
    if (doesCDMSupportWindowsVersion) {
      return false;
    }
  }

  if (bannerRestrictions?.failedPaymentOnly) {
    return user?.subscription?.status === 'failed';
  }

  if (bannerRestrictions?.promoPromotedType) {
    return bannerRestrictions?.promoPromotedType === promoPromotedType;
  }

  return true;
};
