import {
  BannersOnPages,
  DismissibleBannerConfiguration,
  NonDismissibleBannerConfiguration,
} from '@app/services/banners/banners';

import FailedPaymentBanner from '@app/components/banners/FailedPaymentBanner';
import PitchBanner from '@app/components/pitch-banner/PitchBanner';
import CDMExpiryWarningBanner from '@app/components/promotional-banner/banner-content/CDMExpiryWarningBanner';
import FlashSaleBanner from '@app/components/promotional-banner/banner-content/FlashSaleBanner';
import GiftingXmas2024Banner from '@app/components/promotional-banner/banner-content/GiftingXmas2024Banner';
import MubiEditionsBanner from '@app/components/promotional-banner/banner-content/MubiEditionsBanner';
import NotebookIssue6Banner from '@app/components/promotional-banner/banner-content/NotebookIssue6Banner';
import RetrospectiveBanner2024 from '@app/components/promotional-banner/banner-content/RetrospectiveBanner2024';
import TheSubstanceBanner from '@app/components/promotional-banner/banner-content/TheSubstanceBanner';

// Info here: https://docs.google.com/spreadsheets/d/15wHB4UsO7FhpdZMlTTYvBQel66u80gRUf_RzxTTasmQ/edit?gid=1500590971#gid=1500590971
const restrictedCountriesTheSubstance = [
  'AS',
  'AR',
  'AT',
  'BS',
  'BZ',
  'BM',
  'BO',
  'BR',
  'IO',
  'CA',
  'KY',
  'CL',
  'CO',
  'CR',
  'CY',
  'EC',
  'SV',
  'FK',
  'DE',
  'GI',
  'GU',
  'GT',
  'GG',
  'GY',
  'HN',
  'IN',
  'IE',
  'IM',
  'JE',
  'MT',
  'MH',
  'MX',
  'FM',
  'NI',
  'MP',
  'PW',
  'PA',
  'PY',
  'PE',
  'PN',
  'PR',
  'SH',
  'GS',
  'TR',
  'TC',
  'GB',
  'US',
  'UM',
  'UY',
  'VE',
  'VI',
];

const pitchBanner: NonDismissibleBannerConfiguration = {
  bannerType: 'Pitch Banner',
  isDismissible: false,
  bannerComponent: ({ film, filmGroupId }) => (
    <PitchBanner film={film} filmGroupId={filmGroupId} />
  ),
  restrictions: {
    unsubscribedOnly: true,
  },
};

const theSubstanceBanner: DismissibleBannerConfiguration = {
  bannerType: 'THE SUBSTANCE BANNER',
  bannerComponent: ({ cookiePrefix }) => (
    <TheSubstanceBanner cookiePrefix={cookiePrefix} />
  ),
  isDismissible: true,
  cookiePrefix: 'the_substance_release_banner',
  startShowingOn: '2024-10-24 00:00:00',
  stopShowingOn: '2024-10-31 00:00:00',
  restrictions: {
    geoLocation: restrictedCountriesTheSubstance,
  },
};

const failedPaymentBanner: NonDismissibleBannerConfiguration = {
  bannerType: 'Failed payment banner',
  isDismissible: false,
  bannerComponent: () => <FailedPaymentBanner />,
  restrictions: {
    failedPaymentOnly: true,
  },
};

const flashSaleBanner: DismissibleBannerConfiguration = {
  bannerType: 'Flash Sale Banner Nov 2024',
  isDismissible: true,
  startShowingOn: '2024-11-08',
  stopShowingOn: '2024-11-17 23:59:59',
  cookiePrefix: 'global_fixed_discount_flash_nov_2024',
  showAgainAfterXDays: 2,
  bannerComponent: ({ cookiePrefix, stopShowingOn }) => (
    <FlashSaleBanner endDate={stopShowingOn} cookiePrefix={cookiePrefix} />
  ),
  restrictions: {},
};

const cdmBrowserWarningBanner: DismissibleBannerConfiguration = {
  bannerType: 'CDM revocation browser version warning banner',
  isDismissible: true,
  cookiePrefix: 'cdm_revocation_browser_warning_2024',
  bannerComponent: ({ cookiePrefix }) => (
    <CDMExpiryWarningBanner
      dueToBrowserOrWindowsVersions="browser"
      cookiePrefix={cookiePrefix}
    />
  ),
  restrictions: {
    isCDMRevokedDueToBrowserVersion: true,
  },
};

const cdmWindowsWarningBanner: DismissibleBannerConfiguration = {
  bannerType: 'CDM revocation Windows 7 or 8 warning banner',
  isDismissible: true,
  cookiePrefix: 'cdm_revocation_windows_warning_2024',
  bannerComponent: ({ cookiePrefix }) => (
    <CDMExpiryWarningBanner
      dueToBrowserOrWindowsVersions="windows"
      cookiePrefix={cookiePrefix}
    />
  ),
  restrictions: {
    isCDMRevokedDueToWindowsVersion: true,
  },
};

const notebookIssue6BannerFirstPeriod: DismissibleBannerConfiguration = {
  bannerType: 'Notebook Issue 6 - first period',
  bannerComponent: ({ cookiePrefix }) => (
    <NotebookIssue6Banner cookiePrefix={cookiePrefix} />
  ),
  isDismissible: true,
  cookiePrefix: 'notebookIssue6FirstPeriod',
  startShowingOn: '2024-11-25 00:00',
  stopShowingOn: '2024-12-01 23:59:59',
  restrictions: {
    notSubscribedToNotebookMagazineOnly: true,
    onlyCreatedSubscriptionOverSixMonthsAgo: true,
  },
};

const notebookIssue6BannerSecondPeriod: DismissibleBannerConfiguration = {
  bannerType: 'Notebook Issue 6 - second period',
  bannerComponent: ({ cookiePrefix }) => (
    <NotebookIssue6Banner cookiePrefix={cookiePrefix} />
  ),
  isDismissible: true,
  cookiePrefix: 'notebookIssue6SecondPeriod',
  startShowingOn: '2024-12-15 00:00',
  stopShowingOn: '2024-12-29 23:59:59',
  restrictions: {
    notSubscribedToNotebookMagazineOnly: true,
    onlyCreatedSubscriptionOverSixMonthsAgo: true,
  },
};

const giftingBannerXmas2024: DismissibleBannerConfiguration = {
  bannerType: 'Gifting Xmas 2024',
  bannerComponent: ({ cookiePrefix }) => (
    <GiftingXmas2024Banner cookiePrefix={cookiePrefix} />
  ),
  isDismissible: true,
  cookiePrefix: 'giftingXmas2024',
  startShowingOn: '2024-11-22 00:00',
  stopShowingOn: '2024-12-27 23:59:59',
};

const giftingBannerXmas2024ShowingPageUnsubscribed: DismissibleBannerConfiguration =
  {
    bannerType: 'Gifting Xmas 2024',
    bannerComponent: ({ cookiePrefix }) => (
      <GiftingXmas2024Banner cookiePrefix={cookiePrefix} />
    ),
    isDismissible: true,
    cookiePrefix: 'giftingXmas2024',
    startShowingOn: '2024-12-11 00:00',
    stopShowingOn: '2024-12-16 23:59:59',
    restrictions: {
      unsubscribedOnly: true,
    },
  };

const giftingBannerXmas2024ShowingPageShowingPageAllUsers: DismissibleBannerConfiguration =
  {
    bannerType: 'Gifting Xmas 2024',
    bannerComponent: ({ cookiePrefix }) => (
      <GiftingXmas2024Banner cookiePrefix={cookiePrefix} />
    ),
    isDismissible: true,
    cookiePrefix: 'giftingXmas2024',
    startShowingOn: '2024-12-17 00:00',
    stopShowingOn: '2024-12-27 23:59:59',
  };

const retrospectiveBanner2024: DismissibleBannerConfiguration = {
  bannerType: 'Retrospective 2024',
  bannerComponent: ({ cookiePrefix }) => (
    <RetrospectiveBanner2024 cookiePrefix={cookiePrefix} />
  ),
  isDismissible: true,
  cookiePrefix: 'retro2024',
  restrictions: {
    promoPromotedType: 'retro_2024',
  },
};

const retrospectiveBanner2024ShowingPageUnsubscribed: DismissibleBannerConfiguration =
  {
    bannerType: 'Retrospective 2024',
    bannerComponent: ({ cookiePrefix }) => (
      <RetrospectiveBanner2024 cookiePrefix={cookiePrefix} />
    ),
    stopShowingOn: '2024-12-16 23:59:59',
    isDismissible: true,
    cookiePrefix: 'retro2024',
    restrictions: {
      promoPromotedType: 'retro_2024',
      unsubscribedOnly: true,
    },
  };

const retrospectiveBanner2024ShowingPageAllUsers: DismissibleBannerConfiguration =
  {
    bannerType: 'Retrospective 2024',
    bannerComponent: ({ cookiePrefix }) => (
      <RetrospectiveBanner2024 cookiePrefix={cookiePrefix} />
    ),
    startShowingOn: '2024-12-17 00:00',
    isDismissible: true,
    cookiePrefix: 'retro2024',
    restrictions: {
      promoPromotedType: 'retro_2024',
    },
  };

const editionsBanner: DismissibleBannerConfiguration = {
  bannerType: 'MUBI Editions launch Banner',
  bannerComponent: ({ cookiePrefix }) => (
    <MubiEditionsBanner cookiePrefix={cookiePrefix} />
  ),
  isDismissible: true,
  cookiePrefix: 'editionsLaunchBanner',
  startShowingOn: '2024-12-04 00:00',
  stopShowingOn: '2024-12-14 23:59:59',
  restrictions: {
    allowedInLanguages: ['en'],
    geoLocation: [
      'AT',
      'BE',
      'BG',
      'CA',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'FI',
      'FR',
      'DE',
      'GR',
      'GB',
      'HU',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'NL',
      'PL',
      'PT',
      'RO',
      'SK',
      'SI',
      'ES',
      'SE',
      'CH',
      'TR',
      'US',
    ],
  },
};

// The banners are in priority order for each pathname.
export const bannersOnPages: BannersOnPages = {
  '/': [flashSaleBanner, giftingBannerXmas2024],
  '/showing': [
    cdmBrowserWarningBanner,
    cdmWindowsWarningBanner,
    flashSaleBanner,
    retrospectiveBanner2024ShowingPageUnsubscribed,
    retrospectiveBanner2024ShowingPageAllUsers,
    giftingBannerXmas2024ShowingPageUnsubscribed,
    giftingBannerXmas2024ShowingPageShowingPageAllUsers,
    theSubstanceBanner,
    failedPaymentBanner,
  ],
  '/films/[filmSlug]': [
    cdmBrowserWarningBanner,
    cdmWindowsWarningBanner,
    flashSaleBanner,
    retrospectiveBanner2024,
    giftingBannerXmas2024,
    pitchBanner,
    failedPaymentBanner,
  ],
  '/notebook': [
    editionsBanner,
    flashSaleBanner,
    notebookIssue6BannerFirstPeriod,
    notebookIssue6BannerSecondPeriod,
    pitchBanner,
  ],
  '/notebook/posts/[post_slug]': [editionsBanner],
  '/collections/[collectionSlug]': [
    cdmBrowserWarningBanner,
    cdmWindowsWarningBanner,
    flashSaleBanner,
    retrospectiveBanner2024,
    giftingBannerXmas2024,
    pitchBanner,
    failedPaymentBanner,
  ],
};
